@import '../../../theme/mixins';

.base {
  position: relative;
  max-width: var(--max-container-width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: var(--color-black);
}

.brand {
  position: relative;
  display: block;
  display: flex;
  justify-content: center;
}

.title {
  font-family: var(--font-family-primary);
  font-size: var(--title-font-size);
  font-weight: 700;
  position: relative;
}

.lead {
  position: relative;
}

.name {
  position: relative;
  display: inline-block;
  max-width: 20rem;
  top: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nameContainer {
  position: relative;
  padding: 0 0.4rem;

  @include breakpoint(md) {
    max-width: 30rem;
  }
}

.highlight {
  opacity: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1rem;
  width: 2%;
  background-color: var(--color-accent);
}

.static {
  width: 100%;
  opacity: 1;
}

.back {
  margin-top: 1.5rem;
}

.animation {
  animation: both;
  animation-timing-function: ease-in-out;
  animation-duration: 800ms;
  animation-name: slideRight;
}

@keyframes slideRight {
  0% {
    opacity: 0;
    width: 2%;
  }

  100% {
    opacity: 100%;
    width: 100%;
  }
}