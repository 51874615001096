.default {
  position: relative;
}

.narrow {
  position: relative;
  max-width: 31rem;
}

.centered {
  text-align: center;
  p {
    font-size: 24px;
  }
}
