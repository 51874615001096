.container {
  position: absolute;
  margin-top: -2rem;
  top: 0;
  right: 0;
  z-index: 100;
  text-transform: uppercase;
  color: var(--color-language-list);

  .seperator {
    margin: 0 0.3em;
  }

  .selected {
    background-color: var(--color-selected-language-background);
    color: var(--color-selected-language);
  }

  .option {
    cursor: pointer;
    font-size: 0.75em;
    padding: 0.4rem 0.6rem;
    border-radius: 0.2rem;
  }

  .language {
    display: inline;
  }
}
