@import '../../../theme/mixins';

.base {
  position: relative;
  padding: 1rem 1.25rem 0;
}

.about {
  margin-bottom: -2rem;
}

.container {
  position: relative;
  width: 100%;
  max-width: var(--max-container-width);
  margin-left: auto;
  margin-right: auto;

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @include breakpoint(lg) {
    max-width: var(--max-container-width-lg);
  }
}

.header,
.body,
.footer {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.footer a {
  color: var(--color-white);
  font-weight: normal;
}

.column {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex: 1;

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.containerBody {
  @include breakpoint(lg) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  // Retain size if only group
  .column:only-child {
    max-width: var(--max-container-width);
    margin-left: auto;
    margin-right: auto;
  }
}

// Only add margin if there are 2
.column + .column {
  @include breakpoint(lg) {
    margin-left: 1em;
  }
}

.columnPrimary {
  width: 100%;
  flex: 2;
}

.columnSecondary {
  width: 100%;
  flex: 1.2;
}

.loaderBackground {
  background-color: black;
  opacity: 0.8;
  height: 100%;
  width: 100%;
}

.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loaderText {
  font-weight: 700;
  margin: 1rem 0;
  color: var(--color-white);
}

.spinnerContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  margin-top: auto;
  margin-bottom: auto;
  width: 10rem;
  height: 10rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.spinner {
  border: 6px solid var(--color-primary);
  border-top: 6px solid var(--color-accent);
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
