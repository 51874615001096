.base {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  margin: 0;
}

.label {
  // Visually hidden
  // Reference https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.img {
  width: var(--brand-header-width);
}
