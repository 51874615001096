.container {
  width: 100%;
  padding-bottom: 3rem;
  text-align: center;
  opacity: 0;
}

.title {
  color: var(--color-about-list-text);
  font-size: 22px;
  text-align: center;
}

.image {
  margin: 0 auto;
  width: 80%;
}

.animation {
  animation: both;
  animation-duration: 800ms;
  animation-name: fadeInDown;
}

.divider {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 3rem;
  position: relative;

  .line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--color-about-divider);
  }

  .indexContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 90px;
    display: flex;
    background-color: var(--color-about-divider);
  }

  .index {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-red);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
